//frontend/hostel-frontend/src/pages/ListingDetailsPage.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReviewsSection from '../components/reviews/ReviewsSection';
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
    Card,
    CardContent,
    Skeleton,
    Stack,
    Avatar,
    IconButton,
    useTheme,
    useMediaQuery,
    ImageList,
    ImageListItem
} from '@mui/material';
import {
    MapPin,
    Calendar,
    Heart,
    Share2,
    Phone,
    MessageCircle,
    ChevronLeft,
    ChevronRight
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import axios from '../api/axios';
const ListingDetailsPage = () => {
    const { id } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { user } = useAuth();
    const reviewsRef = useRef(null);

    const [listing, setListing] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [reviewsCount, setReviewsCount] = useState(0);


    useEffect(() => {
        const fetchListing = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/api/v1/marketplace/listings/${id}`);
                setListing(response.data.data);
            } catch (err) {
                console.error('Error fetching listing:', err);
                setError('Не удалось загрузить объявление');
            } finally {
                setLoading(false);
            }
        };

        fetchListing();
    }, [id]);
    const scrollToReviews = () => {
        const reviewsSection = document.getElementById('reviews-section');
        if (reviewsSection) {
            reviewsSection.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };
    const handleFavoriteClick = async () => {
        if (!user) {
            // Можно показать сообщение о необходимости авторизации
            return;
        }

        try {
            if (listing.is_favorite) {
                await axios.delete(`/api/v1/marketplace/listings/${id}/favorite`);
            } else {
                await axios.post(`/api/v1/marketplace/listings/${id}/favorite`);
            }
            setListing(prev => ({
                ...prev,
                is_favorite: !prev.is_favorite
            }));
        } catch (err) {
            console.error('Error toggling favorite:', err);
        }
    };

    const formatPrice = (price) => {
        return new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            maximumFractionDigits: 0
        }).format(price);
    };

    if (loading) {
        return (
            <Container maxWidth="lg" sx={{ py: 4 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Skeleton variant="rectangular" height={400} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Skeleton variant="rectangular" height={200} />
                    </Grid>
                </Grid>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="lg" sx={{ py: 4 }}>
                <Typography color="error">{error}</Typography>
            </Container>
        );
    }

    if (!listing) return null;

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Grid container spacing={4}>
                {/* Галерея изображений */}
                <Grid item xs={12} md={8}>
                    <Box sx={{ position: 'relative' }}>
                        {listing.images && listing.images.length > 0 ? (
                            <>
                                <Box
                                    component="img"
                                    src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${listing.images[currentImageIndex].file_path}`}
                                    alt={listing.title}
                                    sx={{
                                        width: '100%',
                                        height: isMobile ? '300px' : '500px',
                                        objectFit: 'cover',
                                        borderRadius: 2
                                    }}
                                />
                                {listing.images.length > 1 && (
                                    <>
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                left: 8,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                bgcolor: 'background.paper',
                                                '&:hover': { bgcolor: 'background.paper' }
                                            }}
                                            onClick={() => setCurrentImageIndex(prev =>
                                                prev > 0 ? prev - 1 : listing.images.length - 1
                                            )}
                                        >
                                            <ChevronLeft />
                                        </IconButton>
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                bgcolor: 'background.paper',
                                                '&:hover': { bgcolor: 'background.paper' }
                                            }}
                                            onClick={() => setCurrentImageIndex(prev =>
                                                prev < listing.images.length - 1 ? prev + 1 : 0
                                            )}
                                        >
                                            <ChevronRight />
                                        </IconButton>
                                    </>
                                )}
                            </>
                        ) : (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '300px' : '500px',
                                    bgcolor: 'grey.200',
                                    borderRadius: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography color="text.secondary">
                                    Нет изображений
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    {listing.images && listing.images.length > 1 && (
                        <ImageList
                            sx={{ mt: 2, maxHeight: 100 }}
                            cols={Math.min(listing.images.length, 6)}
                            rowHeight={100}
                        >
                            {listing.images.map((image, index) => (
                                <ImageListItem
                                    key={image.id}
                                    sx={{
                                        cursor: 'pointer',
                                        opacity: currentImageIndex === index ? 1 : 0.6,
                                        transition: 'opacity 0.2s',
                                        '&:hover': { opacity: 1 }
                                    }}
                                    onClick={() => setCurrentImageIndex(index)}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${image.file_path}`}
                                        alt={`${listing.title} ${index + 1}`}
                                        style={{
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    )}

                    {/* Описание объявления */}
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h4" gutterBottom>
                            {listing.title}
                        </Typography>

                        <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                                <MapPin size={18} style={{ marginRight: 4 }} />
                                <Typography>
                                    {listing.location || `${listing.city}, ${listing.country}`}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                                <Calendar size={18} style={{ marginRight: 4 }} />
                                <Typography>
                                    {new Date(listing.created_at).toLocaleDateString()}
                                </Typography>
                            </Box>
                            <Box
                                component="button"
                                onClick={scrollToReviews}
                                sx={{
                                    background: 'none',
                                    border: 'none',
                                    color: 'primary.main',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    padding: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    '&:hover': {
                                        color: 'primary.dark'
                                    }
                                }}
                            >
                                {reviewsCount} отзывов
                            </Box>
                        </Stack>
                        <Typography variant="body1" sx={{ mb: 4 }}>
                            {listing.description}
                        </Typography>

                        {/* Отзывы */}
                        <Box id="reviews-section" ref={reviewsRef} sx={{ mt: 4 }}>
                            <ReviewsSection
                                entityType="listing"
                                entityId={parseInt(id)}
                                entityTitle={listing.title}
                                canReview={user && user.id !== listing.user_id}
                                onReviewsCountChange={setReviewsCount}
                            />
                        </Box>
                    </Box>
                </Grid>

                {/* Правая панель */}
                <Grid item xs={12} md={4}>
                    <Box sx={{ position: 'sticky', top: 24 }}>
                        {/* Карточка с ценой и контактами */}
                        <Card elevation={2}>
                            <CardContent>
                                <Typography variant="h4" gutterBottom>
                                    {formatPrice(listing.price)}
                                </Typography>

                                <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        startIcon={<Phone />}
                                    >
                                        Позвонить
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        startIcon={<MessageCircle />}
                                    >
                                        Написать
                                    </Button>
                                </Stack>

                                <Stack direction="row" spacing={1}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        startIcon={<Heart fill={listing.is_favorite ? 'currentColor' : 'none'} />}
                                        onClick={handleFavoriteClick}
                                    >
                                        {listing.is_favorite ? 'В избранном' : 'В избранное'}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        startIcon={<Share2 />}
                                    >
                                        Поделиться
                                    </Button>
                                </Stack>
                            </CardContent>
                        </Card>

                        {/* Карточка продавца */}
                        <Card elevation={2} sx={{ mt: 2 }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Продавец
                                </Typography>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar
                                        src={listing.user?.picture_url}
                                        alt={listing.user?.name}
                                        sx={{ width: 56, height: 56 }}
                                    />
                                    <Box>
                                        <Typography variant="subtitle1">
                                            {listing.user?.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            На сайте с {new Date(listing.user?.created_at).toLocaleDateString()}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ListingDetailsPage;